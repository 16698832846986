import * as React from 'react'

import ContactWays from '../components/ContactWays'
import Hero from '../components/NotFound/Hero'
import Layout from '../layouts'

const ContactPage: React.FC = () => {
  return (
    <Layout>
      <Hero />
      <ContactWays />
    </Layout>
  )
}

export default ContactPage
