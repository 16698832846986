import * as React from 'react'

import Button from '../../shared/Button'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Image } from '../../shared/Image'
import { Description, MainTitle } from '../../shared/Typography'
import { INotFound } from './types'

interface IProps {
  data: INotFound
}

export default function renderHeroNotfound({ data }: IProps) {
  const cols = ['100%', '50%']
  const padd = ['0', '6em']

  return (
    <Container>
      <Flex
        alignItems="center"
        mb={['7.5em', '7em']}
        mt={['2em', '5em']}
        px={[null, '0', '6em']}
        flexWrap="wrap"
      >
        <Box width={cols} pr={padd} mb="2.5em">
          <MainTitle mb="1rem">{data.title}</MainTitle>
          <Description mb="1rem" color="grey">
            {data.description}
          </Description>
          {data.ctaLink && <Button to={data.ctaLink}>{data.cta}</Button>}
        </Box>

        <Box width={cols} pl={padd}>
          <Image path="notFoundHero" alt={data.title} />
        </Box>
      </Flex>
    </Container>
  )
}
